body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

header.MuiPaper-root {
  background-color: #1f2635;
}

.gameEdit .input.MuiFilledInput-input {
  width: 100%;
  max-width: 100vw;
  min-width: 320px;
}

.RaAppBar-toolbar {
  display: grid !important;
  grid-template-columns: 60px 240px 1fr 240px 60px 50px;
  padding-right: 10px !important;
}

.RaAppBar-menuButton {
  justify-self: left;
}

.MuiTypography-h6 {
}

.paAppBarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1ghzyfa-RaLoadingIndicator-root {
  display: flex;
  justify-content: right;
}

.paLogo {
  display: block;
  width: 32px;
  margin-right: 12px;
}
.paLogoLogin {
  width: 50px;
  height: 50px;
  justify-content: center;
}
.paLogoLogin img {
  text-align: center;
}

.MuiTypography-h6 {
  font-size: 16px;
}
.MuiTextField-root.ra-input {
  width: 100%;
  max-width: 800px;
}
.react-admin-title {
  font-size: 1em !important;
}
.loginPage #main-content > div {
  background-image: radial-gradient(circle at 50% 14em, #1f2635 0%, #1f2635 60%, #1f2635 100%);
}
.loginPage #main-content {
  padding-left: 0px;
  padding-right: 0px;
}
.loginPage .MuiDrawer-root {
  display: none;
}
.loginPage .RaAppBar-appBar .MuiButtonBase-root {
  visibility: hidden;
}
.loginPage .MuiDrawer-paperAnchorLeft {
  visibility: hidden;
}

.RaLoginForm-button {
  background-color: #ffa300 !important;
}

#main-content .RaLogin-card {
  margin-top: 30px;
}

.loginContentWrapper {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  color: #fff;
}

.RaMenuItemLink-active {
  font-weight: bold !important;
}

.RaDatagrid-row {
  border-bottom: ridge;
  border-color: #f5f5f54d;
}
.RaDatagrid-rowEven {
  background-color: #f5f5f5;
}

.paAppBarUserName {
  font-size: 14px;
  text-align: right;
}

@media (max-width: 600px) {
  .paAppBarLogoName {
    display: none;
  }
  .MuiToolbar-root {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .paAppBarUserName {
    font-size: 12px;
  }
}
div#gameDescripint {
  min-height: 150px;
}
div#attributes\.gameDescripint {
  min-height: 150px;
}
.css-uziais-MuiStack-root-RaLabeled-root-RaFileInput-root .RaFileInput-dropZone {
  background: #0000000a !important;

}
.duplicate-value input {
  border-color: red;
}
.MuiTypography-root.MuiTypography-body2.MuiListItemText-primary {
  font-size: 1.0rem;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}
